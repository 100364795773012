<template>
  <section class="Content-Page">
    <div class="card">
      <div class="card-title">Config Settings</div>
      <div class="card-body">
        <div class="row pt-2 pb-2">
      <div class="col-md-4 col-lg-3 col-sm-6 col-6 pr-2">
        <router-link :to="`/settings/edit-paypal`">
          <div class="p-2 border">
            <img
              src="@/assets/images/paypal.png"
              class="img-fluid"
            />
            <h5 class="text-center text-dark pt-2 mb-0">PayPal</h5>
          </div>
        </router-link>
      </div>
      <div class="col-md-4 col-lg-3 col-sm-6 col-6 pl-2">
        <router-link :to="`/settings/edit-stripe`">
          <div class="p-2 border">
            <img
              src="@/assets/images/stripe.png"
              class="img-fluid"
            />
            <h5 class="text-center text-dark pt-2 mb-0">Stripe</h5>
          </div>
        </router-link>
      </div>
    </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "config-settings",
};
</script>
<style scoped>
a:hover{  
    text-decoration: none;
}
</style>